import React from 'react';

import { getClickType } from '../../../../../sharedModules/getClickType';
import { encodeQueryString } from '../../../../../utils/encodeQueryString';
import type { Deal } from '../../../../types/Deal';
import { useHawkWidgetContext } from '../../HawkWidget/HawkWidgetProviderContext';
import { TargetEnum } from '../../TrackedLink/model';

interface VultureButtonAffiliateLinkWrapperProps {
  setPopupDeal?: (deal: Deal) => void;
  setIsSeen?: (value: boolean) => void;
  deal: Deal;
  elementType: string;
  children: React.ReactNode;
  stringOfSeen: string;
}

const getFuturePageUrl = (deal: Deal, stringOfSeen: string): string => {
  const baseUrl = window.location.href.split(/[?#]/)[0];

  return `${baseUrl}${encodeQueryString({
    visibleMatchId: deal.match_id,
    seenMatchId: stringOfSeen,
  })}${document.location.hash}`;
};

export const VultureButtonAffiliateLinkWrapper: React.FC<
  VultureButtonAffiliateLinkWrapperProps
> = ({ setPopupDeal, setIsSeen, deal, elementType, children, stringOfSeen }) => {
  const {
    articleUrl,
    genericSharedComponents: { AffiliateLink },
  } = useHawkWidgetContext();

  const handleLinkClick = setPopupDeal
    ? (): void => {
        const futurePageUrl = getFuturePageUrl(deal, stringOfSeen);
        window.open(futurePageUrl);
        setPopupDeal(deal);
        setIsSeen?.(true);
      }
    : (): null => null;

  return (
    <AffiliateLink
      deal={deal}
      className={elementType === 'button' ? 'vulture-button' : 'container'}
      linkClick={handleLinkClick}
      customTarget={TargetEnum.SELF}
      clickType={getClickType({
        link: deal.offer.link,
        articleUrl,
        productType: deal.product_type,
      })}
    >
      {children}
    </AffiliateLink>
  );
};
