import React from 'react';

import { ClickType } from '../../../../sharedModules/getClickType';
import { getDealImage } from '../../../modules/getDealImage';
import type { WidgetProps } from '../../../types/WidgetProps';
import AffiliateLink from '../../GenericComponents/AffiliateLink/AffiliateLink';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { VultureButtonAffiliateLinkWrapper } from '../../GenericComponents/Vulture/Button/VultureButtonAffiliateLinkWrapper';

import styles from './styles/scrollable-tiles.css';
import { Tile } from './Tile';

interface ScrollableTilesProps {
  dealData: WidgetProps['dealData'];
  firstTileRef: (node?: Element | null) => void;
  lastTileRef: (node?: Element | null) => void;
}

export const ScrollableTiles: React.FC<ScrollableTilesProps> = (props) => {
  const { firstTileRef, dealData, lastTileRef } = props;

  const { seenMatchId, setPopupDeal } = useHawkWidgetContext();

  return (
    <>
      {dealData.deals.map((deal, i) => {
        const tileRef = {
          0: firstTileRef,
          [dealData.deals.length - 1]: lastTileRef,
        }[i];
        const { merchant, promos, offer } = deal;
        const { alt, url } = getDealImage(deal, ['merchant']);
        const TileComponent = (): JSX.Element => {
          return (
            <Tile
              key={deal.id}
              logoUrl={url}
              title={merchant.name || ''}
              alt={alt || ''}
              exclusive={promos?.some((promo) => promo.type === 'exclusive')}
              description={offer.name}
              promos={promos}
            />
          );
        };

        if (deal.airedale_info?.canonicalUrl) {
          return (
            <div key={deal.id} ref={tileRef || null} className={styles['tile-link-container']}>
              <AffiliateLink
                clickType={ClickType.INTERNAL}
                deal={deal}
                customUrl={deal.airedale_info?.canonicalUrl}
              >
                <TileComponent />
              </AffiliateLink>
            </div>
          );
        }

        const arrayOfSeen = [
          ...((seenMatchId && seenMatchId.split(',')) || []),
          String(deal?.match_id),
        ];
        const stringOfSeen = arrayOfSeen.join(',');
        return (
          <div key={deal.id} ref={tileRef || null} className={styles['tile-link-container']}>
            <VultureButtonAffiliateLinkWrapper
              setPopupDeal={setPopupDeal}
              deal={deal}
              elementType="container"
              stringOfSeen={stringOfSeen}
            >
              <TileComponent />
            </VultureButtonAffiliateLinkWrapper>
          </div>
        );
      })}
    </>
  );
};
